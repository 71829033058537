import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <div className="section bottom container">

    <h1>Seite nicht gefunden!</h1>
    <p>Sie sind auf einer Seite gelandet die nicht existiert.</p>
    </div>
  </Layout>
);

export default NotFoundPage
